import axios from "axios";

let _configData = window._env_ || {};

const _env = "dev";

export const setConfig = (config) => (_configData = config);

export const getConfig = () => _configData;

export const getEnv = () => window?._env_;

const _fetchConfig = async () => {
  if (!window._env_.CONFIG_URL) return window._env_;

  const res = await axios.get(window._env_.CONFIG_URL);
  return res.data[window.location.hostname];
};

const developmentConfigs = (env) => {
  const isDev = env === "dev";
  const oldApiUri = `tiger-api.inno${env}.site`;
  const platformUri = isDev ? "tiger-dev.servicelab.sh" : oldApiUri;

  switch (env) {
    case "dev":
    case "stg":
    case "uat":
      return {
        API_URL: `https://${platformUri}/platform`,
        PLATFORM_URL: `https://en-vd004-universe-portal.inno${env}.site`,
        PLATFORM_API_URL: `https://${platformUri}/platform`,
        FE_CDN_URL: `https://fe-source.${env}.mppwr.com`,
        DEPLOY_ENV: env,
        errorCode: "errorCode",
      };
  }
};

(async () => {
  let _config;

  try {
    if (!process?.env?.production) _config = await developmentConfigs();
    else _config = await _fetchConfig();
  } catch (error) {
    console.log("error", error);
  }
})();

if (!window._env_) setConfig(developmentConfigs(_env));
